import { Outlet } from "react-router-dom";
import Header from "./views/Header";
import Footer from "./views/Footer";
import Banner from "./views/Banner";
import styles from "./App.module.scss";

const App = () => {
  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.main}>
        <Banner />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default App;
