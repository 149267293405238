import styles from "./index.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <span>
        为了您的健康，请合理控制游戏时间。《健康游戏忠告》
        抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。
      </span>
      <span>
        Copyright© 深圳市卡拉网络科技有限公司（Shenzhen Coalaa Network
        Technology Co., Ltd） 版权所有
      </span>
      <span>
        地址：深圳市南山区西丽街道西丽留仙洞中山园路1001号TCL科学园区研发楼E1栋8层B单元802号
      </span>
    </div>
  );
};

export default Footer;
