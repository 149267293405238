import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";
import logoImg from "../../assets/logo.png";

const Header = () => {
  return (
    <div className={styles.header}>
      <div className={styles.headerCon}>
        <NavLink className={styles.logo} to="/about">
          <img src={logoImg} alt="logo" />
        </NavLink>
        <div className={styles.navs}>
          <NavLink
            className={({ isActive }) => (isActive ? styles.on : "")}
            to="/"
            children="关于我们"
          />
          <NavLink
            className={({ isActive }) => (isActive ? styles.on : "")}
            to="/contact-us"
            children="联系我们"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
