import { createHashRouter, Navigate } from "react-router-dom";
import App from "../App";
import About from "../views/About";
import ContactUs from "../views/ContactUs";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <About />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

export default router;
