import styles from "./index.module.scss";

const ContactUs = () => {
  return (
    <div className={styles.content}>
      <div className={styles.box}>
        <div className={styles.logo}>
          <i className={styles.icon}></i>
        </div>
        <div className={styles.info}>
          <p>电话：0755-86387087</p>
          <p>邮箱：ipk@coalaa.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
