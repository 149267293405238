import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import styles from "./index.module.scss";

const posterList = ["fr", "tl", "id", "vn"];

SwiperCore.use([Autoplay]);

const About = () => {
  return (
    <div className={styles.content}>
      <Swiper
        className={styles.swiperWrap}
        modules={[Pagination]}
        loop
        autoplay
        pagination
      >
        {posterList.map((name, key) => (
          <SwiperSlide key={key}>
            <img
              className={styles.poster}
              src={require(`../../assets/poster/${name}.jpg`)}
              alt={`../../assets/poster/${name}.jpg`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.area}>
        <div className={styles.card}>
          <h3>卡拉简介</h3>
          <div className={styles.cont}>
            <p>
              深圳市卡拉网络科技有限公司（Shenzhen Coalaa Network Technology
              Co.,
              Ltd）成立于2011年11月29日，是一家专注于互联网游戏开发及运营的公司。卡拉网络公司拥有游戏行业出色的研发及运营团队人员，深耕多地区市场，致力于为用户提供健康、益智、本地化的游戏娱乐产品。
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <h3>公司目标</h3>
          <div className={styles.cont}>
            <p>
              公司以为用户提供优质的游戏服务为中心，坚持创新、持续出品优质的游戏产品，成为全球用户喜爱的游戏厂商。
            </p>
            <p>
              公司地址：深圳市南山区西丽街道西丽留仙洞中山园路1001号TCL科学园区研发楼E1栋8层B单元802号。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
