import styles from "./index.module.scss";
import bannerImg from "../../assets/banner.jpg";

const Footer = () => {
  return (
    <div className={styles.banner}>
      <img src={bannerImg} alt="banner" />
    </div>
  );
};

export default Footer;
